import React from 'react';
import { Router } from '@abyss/web/ui/Router';
import { OtpPage } from '@src/pages/OtpPage';
import { LandingPage } from '../pages/LandingPage';
import { TfnSupportPage } from '../pages/TfnSupportPage';
import { SuccessPage } from '../pages/SuccessPage';

export const Routes = () => {
  return (
    <Router.Routes title="{{title}} | Abyss">
      <Router.Route path="/" element={<LandingPage />} />
      <Router.Route path="/apple" element={<LandingPage />} />
      <Router.Route path="/success" element={<SuccessPage />} />
      <Router.Route path="/tfnHelp" element={<TfnSupportPage />} />
      <Router.Route path="/otp" element={<OtpPage />} />
    </Router.Routes>
  );
};
