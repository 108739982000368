import React from 'react';
import { Heading } from '@abyss/web/ui/Heading';
import { Layout } from '@abyss/web/ui/Layout';
import { tfnSupportPageMessage } from '../../specs/constants/formConstants';

export const TfnSupportPage = () => {
  return (
    <React.Fragment>
      <Layout.Stack alignLayout="center">
        <p>
          <br />
        </p>
        <Heading textAlign="center">Unable to verify information</Heading>
        <div style={{ padding: '10px', margin: '15px', fontSize: '16px' }}>
          {tfnSupportPageMessage}
        </div>
      </Layout.Stack>
    </React.Fragment>
  );
};
