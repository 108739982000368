export const firstNameField = { role: 'textbox', name: 'First Name' };
export const lastNameField = { role: 'textbox', name: 'Last Name' };
export const phoneNumberField = { role: 'textbox', name: 'Phone number' };
export const dobField = { role: 'textbox', name: 'Date of birth' };
export const submitButtonField = { role: 'button', name: 'I agree' };
export const checkbox = { role: 'checkbox' };

// Test Input Constants
export const testFirstName = 'Test First';
export const testLastName = 'Test Last';
export const testPhoneNumber = '1111111111';
export const testDob = '11112000';

// Backend Paths
export const idProofPath = '/api/verifyidentity';
export const saveConsentStatusPath = '/api/updateconsentstatus';
export const updateSDEPath = '/api/updateSDE';

// Page Paths
export const successPath = '/success';
export const tfnPath = '/tfnHelp';

// API Request Params
export const idProofRequestParams = {
  first_name: testFirstName,
  last_name: testLastName,
  phone: {
    country_code: '1',
    phone_number: testPhoneNumber,
  },
  date_of_birth: '2000-11-11',
};

export const tfnSupportPageMessage =
  'I’m sorry but I wasn’t able to verify your information. To honor your privacy we ask that you please call us. Visit uhc.com/contactus to find the best support number.';
