/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-unused-expressions */

import React from 'react';
import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { styled } from '@abyss/web/tools/styled';
import { Layout } from '@abyss/web/ui/Layout';
import { Button } from '@abyss/web/ui/Button';
import { Text } from '@abyss/web/ui/Text';
import { useForm } from '@abyss/web/hooks/useForm';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { Heading } from '@abyss/web/ui/Heading';
import { HttpClient } from '@optum-async-messaging/uhc-cvx-common-ts-lib';
import { TextInput } from '@abyss/web/ui/TextInput';
import {
  accepted,
  authorized,
  authenticationPath,
  consentPath,
} from '../../constants/constants';
import {
  updateSDEInAgentWorkspace,
  updateCCS,
} from '../LandingPage/utils/util';

const RootContainer = styled(Layout.Stack, {
  padding: '6rem',
  minHeight: '100vh',
  justifyContent: 'space-between',
  variants: {
    medium: {
      true: {
        padding: '2rem',
      },
    },
  },
});

export const OtpPage = () => {
  const isMedium = useMediaQuery('(max-width: 744px)');
  const httpClient = new HttpClient();
  const form = useForm();
  const { navigate, getLocation } = useRouter();
  // All passed params are here.
  const passedParams = getLocation().state;

  // Need all fields in order to verify OTP and update SDEs
  // So go to error page if any fields are not present
  if (
    passedParams === null ||
    passedParams === undefined ||
    passedParams.opaqueId === undefined ||
    passedParams.otpId === undefined ||
    passedParams.firstName === undefined ||
    passedParams.lastName === undefined ||
    passedParams.dob === undefined ||
    passedParams.phoneNumber === undefined
  ) {
    // use this instead of navigate bc otherwise nothing happens
    window.location.href = '/tfnHelp';
  }

  const phoneNumberLastFour = passedParams.phoneNumber.slice(-4);

  const onSubmit = async (data: any) => {
    try {
      const result = await httpClient.post<any>('api/submit', {
        otp: data.otpField,
        otpId: passedParams.otpId,
        mode: 'validate',
      });

      if (result.data.success) {
        const updateConsentResult = await updateCCS(
          httpClient,
          consentPath,
          passedParams.opaqueId,
          accepted
        );

        const updateSDEResult = await updateSDEInAgentWorkspace(httpClient, {
          opaqueId: passedParams.opaqueId,
          firstName: passedParams.firstName,
          lastName: passedParams.lastName,
          dob: passedParams.dob,
          phoneNumber: passedParams.phoneNumber,
        });

        const updateAuthResult = await updateCCS(
          httpClient,
          authenticationPath,
          passedParams.opaqueId,
          authorized
        );

        /* eslint-disable-next-line no-alert */
        updateConsentResult && updateSDEResult && updateAuthResult
          ? navigate('/success')
          : navigate('/tfnHelp');
      } else {
        navigate('/tfnHelp');
      }
    } catch (error) {
      navigate('/tfnHelp');
    }
  };

  return (
    <RootContainer
      alignLayout="center"
      alignItems="center"
      grow
      medium={isMedium}
    >
      <FormProvider state={form} onSubmit={onSubmit}>
        <Layout.Stack space={20} grow>
          <Heading offset={2}>Check your text messages</Heading>
          <Text>
            <Text>A One-time passcode has been sent to</Text>
            <Text style={{ fontWeight: 'bold' }}>
              {' '}
              XXX-XXX-{phoneNumberLastFour}
            </Text>
            <Text>. Enter the code below.</Text>
          </Text>
          <Text style={{ fontSize: 14 }}>
            The code will expire after 10 minutes.
          </Text>
          <TextInput
            label="One-time passcode"
            model="otpField"
            placeholder="123456"
            inputMode="numeric"
            validators={{
              required: true,
              validate: (v) => {
                const numRegex = new RegExp(/^\d{0,6}$/);
                const validCode = numRegex.test(v);
                return validCode ? true : 'Invalid code. Must be 6 digits.';
              },
            }}
          />
          <Button type="submit">Verify</Button>
        </Layout.Stack>
      </FormProvider>
    </RootContainer>
  );
};
