import { config } from '@abyss/web/tools/config';
import { lagoon } from '@abyss/utility/tools/lagoon';
import { LAGOON_NAMESPACE, LAGOON_ENV } from '../../constants/lagoonConstants';

/**
 * Gets the specified value given a key from a specific Lagoon table
 * @param key - the key used to get the value for
 * @param tableName - the table to find the key in
 * @returns the value for the specified key
 */
export const getLagoonValue = async (key, tableName) => {
  // initialize lagoon and get all data for namespace/env
  const lag = lagoon(LAGOON_NAMESPACE, config(LAGOON_ENV));
  const allTables = await lag.initialize();
  // get data specific to the given table
  const tableFunction = allTables[tableName];
  return tableFunction(key);
};
