import React, { useEffect } from 'react';
import {
  AMB_FORM_TABLE_NAME,
  FORM_DEFLECT_LINK,
} from '../../constants/lagoonConstants';
import { getLagoonValue } from '../../common/util/lagoonHelper';

export const SuccessPage = () => {
  useEffect(() => {
    getLagoonValue(FORM_DEFLECT_LINK, AMB_FORM_TABLE_NAME).then((response) => {
      // This will redirect the user to their Messages application where they started the conversation.
      window.location.href = response.value;
    });
  });

  return <React.Fragment />;
};
