/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-unused-expressions */

import React, { useState, useEffect } from 'react';
import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { styled } from '@abyss/web/tools/styled';
import { Layout } from '@abyss/web/ui/Layout';
import { DateInput } from '@abyss/web/ui/DateInput';
import { Text } from '@abyss/web/ui/Text';
import { TextInput } from '@abyss/web/ui/TextInput';
import { TextInputArea } from '@abyss/web/ui/TextInputArea';
import { Button } from '@abyss/web/ui/Button';
import { Checkbox } from '@abyss/web/ui/Checkbox';
import { useForm } from '@abyss/web/hooks/useForm';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { Heading } from '@abyss/web/ui/Heading';
import { HttpClient } from '@optum-async-messaging/uhc-cvx-common-ts-lib';
import { LoadingSpinner } from '@abyss/web/ui/LoadingSpinner';
import {
  checkBoxLabel,
  checkBoxLabelOtp,
  formHeader,
  DEFLECTION_SESSION_NAMESPACE,
  accepted,
  authorized,
  authenticationPath,
  consentPath,
} from '../../constants/constants';
import { getLagoonValue } from '../../common/util/lagoonHelper';
import {
  HIPAA_ENABLED,
  AMB_FORM_TABLE_NAME,
  CONSENT_LANGUAGE_TABLE_NAME,
} from '../../constants/lagoonConstants';
import {
  updateSDEInAgentWorkspace,
  retrieveSessionDataInCcs,
  idProofMember,
  updateCCS,
} from './utils/util';

const RootContainer = styled(Layout.Stack, {
  padding: '6rem',
  minHeight: '100vh',
  justifyContent: 'space-between',
  variants: {
    medium: {
      true: {
        padding: '2rem',
      },
    },
  },
});

const centerStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
};

export const LandingPage = () => {
  const form = useForm();
  const httpClient = new HttpClient();
  const isMedium = useMediaQuery('(max-width: 744px)');
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [showHipaaBox, setShowHipaaBox] = useState(true);
  const { navigate } = useRouter();
  const [consentLanguage, setConsentLanguage] = useState('');
  const [loading, setLoading] = useState(true);
  const params = new URLSearchParams(window.location.search);
  const possession = params.get('possessionCheck');
  const checkBoxConsentLabel =
    possession === 'pass' ? checkBoxLabel : checkBoxLabelOtp;
  const opaqueId = params.get('opaqueId');
  const sessionId = params.get('sessionId') || '';

  const fetchLanguage = async () => {
    try {
      const response = await getLagoonValue('1', CONSENT_LANGUAGE_TABLE_NAME);
      if (response.consentLanguage === undefined) {
        navigate('/tfnHelp');
      }
      setConsentLanguage(response?.consentLanguage);
    } catch (error) {
      console.error('Error setting the consent language', error);
      navigate('/tfnHelp');
    }
    if (!opaqueId) {
      console.error('Missing url param opaqueId');
      navigate('/tfnHelp');
    }
  };

  const getHipaa = async () => {
    try {
      const hipaaResponse = await getLagoonValue(
        HIPAA_ENABLED,
        AMB_FORM_TABLE_NAME
      );
      const hipaaEnabled = hipaaResponse?.value === 'true';

      setShowHipaaBox(hipaaEnabled);
    } catch (error) {
      console.error('Error setting the consent language', error);
      navigate('/tfnHelp');
    }
  };

  const retrieveSessionData = async () => {
    try {
      const data = await retrieveSessionDataInCcs(
        httpClient,
        sessionId,
        DEFLECTION_SESSION_NAMESPACE
      );
      if (data) {
        form.setValue('firstName', data.firstName ? data.firstName : '');
        form.setValue('lastName', data.lastName ? data.lastName : '');
        form.setValue('phoneNumber', data.phNum ? data.phNum : '');
        const memberDob = data?.dob ? `${data.dob}` : '';
        if (memberDob.length === 8) {
          const year = memberDob.substring(0, 4);
          const month = memberDob.substring(4, 6);
          const day = memberDob.substring(6, 8);
          form.setValue('dob', month + day + year);
        }
      }
    } catch (error) {
      console.error('Error retrieving session data', error);
    }
  };

  useEffect(() => {
    const initialize = async () => {
      try {
        await fetchLanguage();
        await getHipaa();
        await retrieveSessionData();
      } catch (error) {
        console.error('Initialization error', error);
        navigate('/tfnHelp');
      } finally {
        setLoading(false); // Set loading to false after all async operations are done
      }
    };
    initialize();
  }, []);

  const onSubmit = async (data: any) => {
    // When submitting the form with a DOB that was pre-populated from the CCS
    // it may not have the slashes in the date. This will add them back in.
    if (!data.dob.includes('/')) {
      // eslint-disable-next-line no-param-reassign
      data.dob = `${data.dob.substring(0, 2)}/${data.dob.substring(
        2,
        4
      )}/${data.dob.substring(4, 8)}`;
    }

    const idProofResult = await idProofMember(
      httpClient,
      data.firstName,
      data.lastName,
      data.phoneNumber,
      data.dob
    );
    if (possession !== 'pass') {
      if (idProofResult) {
        const result = await httpClient.post<any>('api/submit', {
          phoneNumber: data.phoneNumber,
          mode: 'generate',
        });

        if (result.data.success === false) {
          navigate('/tfnHelp');
        } else {
          // if not pass, go to the otp page, but take the filled in form fields
          // pass form data to otp page
          // for otp validation and update SDEs/ccs
          navigate('/otp', {
            state: {
              opaqueId,
              firstName: data.firstName,
              lastName: data.lastName,
              dob: data.dob,
              phoneNumber: data.phoneNumber,
              otpId: result.data.otpId,
            },
          });
        }
      } else {
        setShowErrorMessage(true);
        setDisableSubmitButton(true);
      }
      return;
    }

    if (idProofResult) {
      const updateConsentResult = await updateCCS(
        httpClient,
        consentPath,
        opaqueId,
        accepted
      );

      const updateSDEResult = await updateSDEInAgentWorkspace(httpClient, {
        opaqueId,
        firstName: data.firstName,
        lastName: data.lastName,
        dob: data.dob,
        phoneNumber: data.phoneNumber,
      });

      const updateAuthResult = await updateCCS(
        httpClient,
        authenticationPath,
        opaqueId,
        authorized
      );

      updateConsentResult && updateSDEResult && updateAuthResult
        ? navigate('/success')
        : navigate('/tfnHelp');
    } else {
      setShowErrorMessage(true);
      setDisableSubmitButton(true);
    }
  };

  if (loading) {
    // while fetching flags
    return process.env.NODE_ENV === 'test' ? (
      <div>Loading...</div>
    ) : (
      <div style={centerStyle}>
        <LoadingSpinner isLoading={loading} />
      </div>
    );
  }

  return (
    <RootContainer
      alignLayout="center"
      alignItems="center"
      grow
      medium={isMedium}
    >
      <FormProvider state={form} onSubmit={onSubmit}>
        <Layout.Stack space={20} grow>
          <Heading offset={2}>
            {showHipaaBox ? formHeader.hipaa : formHeader.userVerification}
          </Heading>
          {showHipaaBox && (
            <TextInputArea
              rows="10"
              disableAutoSize="true"
              isDisabled={false}
              value={consentLanguage}
              readOnly
            />
          )}
          <TextInput
            label="First Name"
            model="firstName"
            validators={{
              required: true,
              validate: (v) => {
                const numRegex = new RegExp(/\d/);
                const validFirstName = numRegex.test(v);
                return validFirstName ? 'Invalid first name' : true;
              },
            }}
          />
          <TextInput
            label="Last Name"
            model="lastName"
            validators={{
              required: true,
              validate: (v) => {
                const numRegex = new RegExp(/\d/);
                const validLastName = numRegex.test(v);
                return validLastName ? 'Invalid last name' : true;
              },
            }}
          />
          <DateInput
            label="Date of birth"
            model="dob"
            subText="mm/dd/yyyy"
            validators={{ required: true }}
          />
          <TextInput
            label="Phone number"
            placeholder="(___) ___-____"
            model="phoneNumber"
            mask="phone"
            validators={{
              required: true,
              validate: (v) => {
                const phoneRegex = new RegExp(
                  /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
                );
                const validNumber = phoneRegex.test(v);
                return !validNumber ? 'Invalid phone number' : true;
              },
            }}
          />
          <Checkbox
            label={checkBoxConsentLabel}
            model="signature"
            validators={{
              required: true,
            }}
          />
          {showErrorMessage === true && (
            <Text color="red">
              I'm sorry but I wasn't able to verify your information. To honor
              your privacy we ask that you please call us. Visit
              uhc.com/contactus to find the best support number.
            </Text>
          )}
          <Button type="submit" isDisabled={disableSubmitButton}>
            I agree
          </Button>
        </Layout.Stack>
      </FormProvider>
    </RootContainer>
  );
};
