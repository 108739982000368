export const checkBoxLabel =
  'I acknowledge that my eSignature is the electronic equivalent of a hand written signature.';
export const checkBoxLabelOtp =
  'I acknowledge that my eSignature is the electronic equivalent of a hand written signature and accept receiving a one-time text message to validate my phone number.';
export const accepted = 'accepted';
export const formHeader = {
  hipaa:
    'Authorization to disclose health information through Apple Messages for Business',
  userVerification: 'Complete the form to authenticate',
};

export const DEFLECTION_SESSION_NAMESPACE = 'deflectionSession';

export const authorized = 'authorized';

export const consentPath = 'updateconsentstatus';

export const authenticationPath = 'updateauthstatus';
