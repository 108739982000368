// eslint-disable-next-line import/no-extraneous-dependencies
import { HttpClient } from '@optum-async-messaging/uhc-cvx-common-ts-lib';
import { format } from 'date-fns';
import { authenticationPath } from '../../../constants/constants';

/**
 * Calculates the age of user given their DOB.
 *
 * @param dobObject dob MM/DD/YYYY as a Date object
 * @return age as a number
 */
const getAge = (dobObject) => {
  const ageDifMs = Date.now() - dobObject.getTime();
  const ageDate = new Date(ageDifMs); // milliseconds from epoch

  return Math.abs(ageDate.getUTCFullYear() - 1970);
};

/**
 * retrieve the session data stored in Liveperson CCS. I.E. firstName,lastName, dob, phone.
 *
 * @param httpClient http client to make calls with
 * @param sessionId the session id under which the data is stored.
 * @param namespace the namespace under which the data is stored.
 */
export const retrieveSessionDataInCcs = async (
  httpClient: HttpClient,
  sessionId: string,
  namespace: string
) => {
  try {
    const result = await httpClient.post<any>('/api/retrieveCcsData', {
      namespace,
      sessionId,
    });
    return result.data?.payload;
  } catch (e) {
    console.error('Error retrieving session data from ccs', e);
    return false;
  }
};

/**
 * Generic CCS update function.
 *
 * @param httpClient http client
 * @param path path of the Azure FaaS function we want to hit
 * @param opaqueId opaque ID
 * @param statusToSave status to save ex. 'accepted'
 */
export const updateCCS = async (
  httpClient: HttpClient,
  path: string,
  opaqueId: null | string,
  statusToSave: string
) => {
  try {
    const result = await httpClient.post<any>(`/api/${path}`, {
      opaqueId,
      statusToSave,
    });
    return result.data.success;
  } catch (e) {
    const errorString =
      path === authenticationPath ? 'authentication' : 'consent';
    console.error(`Error saving users ${errorString} status`, e);
    return false;
  }
};

/**
 * Update SDEs programmatically in agent workspace using values from form.
 *
 * @param httpClient http client to make calls with
 * @param memberInfo   opaque id, first name, last name, dob, phone number
 */
export const updateSDEInAgentWorkspace = async (
  httpClient: HttpClient,
  memberInfo
) => {
  const dobObject = new Date(memberInfo.dob);
  const age = getAge(dobObject);

  const lpSdes = [
    {
      type: 'personal',
      personal: {
        firstname: memberInfo.firstName,
        lastname: memberInfo.lastName,
        contacts: [
          {
            phone: memberInfo.phoneNumber,
          },
        ],
        age: {
          age,
          year: dobObject.getUTCFullYear(),
          month: dobObject.getUTCMonth() + 1,
          day: dobObject.getUTCDate(),
        },
      },
    },
  ];

  const conversationApiPayload = {
    memberId: memberInfo.opaqueId,
    lpSdes,
  };

  try {
    const result = await httpClient.post<any>(
      '/api/updateSDE',
      conversationApiPayload
    );
    return result.data.success;
  } catch (e) {
    console.error('Error updating SDEs', e);
    return false;
  }
};

/**
 * ID Proof member using their first name, last name, dob, and phone number
 *
 * @param httpClient http client to make calls with
 * @param firstName member's first name
 * @param lastName member's last name
 * @param phoneNumber member's phone number
 * @param dateOfBirth member's dob
 * @return boolean whether member is valid or rejected
 */
export const idProofMember = async (
  httpClient: HttpClient,
  firstName,
  lastName,
  phoneNumber,
  dateOfBirth
) => {
  try {
    const formattedDob = format(new Date(dateOfBirth), 'yyyy-MM-dd');
    const formatedNumber = phoneNumber.replace(/[^0-9]/g, '');

    const result = await httpClient.post<any>('/api/verifyidentity', {
      first_name: firstName,
      last_name: lastName,
      phone: {
        country_code: '1',
        phone_number: formatedNumber,
      },
      date_of_birth: formattedDob,
    });

    return (
      result?.status === 200 && result?.data?.result?.toLowerCase() === 'pass'
    );
  } catch (e) {
    console.error('Error id proofing user', e);
    return false;
  }
};
